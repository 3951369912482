export default defineNuxtPlugin(() => {
  // Polyfill for Object.hasOwn if it doesn't exist
  if (!Object.hasOwn) {
    Object.defineProperty(Object, 'hasOwn', {
      value: function (obj: object, prop: PropertyKey): boolean {
        return Object.prototype.hasOwnProperty.call(obj, prop)
      },
      writable: true,
      configurable: true
    })
  }
})
