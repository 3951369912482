export default {
  root: ({ props }) => ({
    class: [
      // Size and Shape
      'min-[320px]:w-full left-0 px-4 min-[400px]:left-auto min-[400px]:w-96 rounded-md !z-[5000]',

      // Positioning
      { '-translate-x-2/4': props.position == 'top-center' || props.position == 'bottom-center' }
    ]
  }),
  container: ({ props }) => ({
    class: [
      'my-4 rounded-md w-full',

      'shadow-lg',
      'bg-white text-black',
      {
        'text-blue-500 dark:text-blue-300': props.message.severity == 'info',
        'text-green-500 dark:text-green-300': props.message.severity == 'success',
        'text-orange-500 dark:text-orange-300': props.message.severity == 'warn',
        'text-red-500 dark:text-red-300': props.message.severity == 'error'
      }
    ]
  }),
  content: {
    class: 'flex items-start p-4'
  },
  icon: {
    class: [
      // Sizing and Spacing
      'w-5 h-5 flex self-center',
      'p-4',
      'rounded-full',
      'mr-2 shrink-0',
      '!text-black dark:text-black bg-blue-400'
    ]
  },
  text: {
    class: [
      // Font and Text
      'text-sm leading-none',
      'ml-2',
      'flex-1'
    ]
  },
  summary: {
    class: 'font-medium block'
  },
  detail: {
    class: 'mt-1.5 block font-light text-black'
  },
  closebutton: {
    class: [
      'translate-y-full',
      // Size
      'w-6 h-6',

      // Spacing and Misc
      'ml-auto relative',

      // Shape
      'rounded-full',

      // Colors
      'bg-transparent',
      'text-[#B3B3B3]',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'ring-none',

      // Misc
      'overflow-hidden'
    ]
  },
  closeicon: {
    class: [
      // Sizing and Spacing
      'w-3 h-3 self-center',
      'shrink-0'
    ]
  },
  transition: {
    enterFromClass: 'opacity-0 translate-y-2/4',
    enterActiveClass: 'transition-[transform,opacity] duration-300',
    leaveFromClass: 'max-h-[1000px]',
    leaveActiveClass:
      '!transition-[max-height_.45s_cubic-bezier(0,1,0,1),opacity_.3s,margin-bottom_.3s] overflow-hidden',
    leaveToClass: 'max-h-0 opacity-0 mb-0'
  }
}
