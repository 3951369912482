export default {
  root: () => ({
    class: [
      // Sizing and Shape
      'min-w-[12rem]',
      'rounded-md',
      // Colors
      'p-[0.5rem]',
      'text-surface-700 dark:text-white'
    ]
  }),
  wrapper: {
    class: ['overflow-auto']
  },
  list: {
    class: 'py-3 list-none m-0'
  },
  item: ({ context }) => ({
    class: [
      // Font
      'font-normal',
      'leading-none',
      // Position
      'relative',
      // Shape
      'border-0',
      'rounded-none',
      // Spacing
      'm-0',
      'py-3 px-1',
      // Color
      { 'text-surface-700 dark:text-white': !context.focused && !context.selected },
      {
        ' text-surface-700 dark:text-white': context.focused && !context.selected
      },
      {
        'bg-primary-100 text-black ': context.focused && context.selected
      },
      {
        'bg-primary-50 text-black ': !context.focused && context.selected
      },
      // States
      {
        'hover:bg-[#121213] text-white ': !context.focused && !context.selected
      },
      {
        'hover:bg-[#121213] text-white hover:!text-white': context.focused && !context.selected
      },
      // Transitions
      'transition',
      'duration-200',
      // Misc
      'cursor-pointer',
      'overflow-hidden',
      'whitespace-nowrap'
    ]
  }),
  itemgroup: {
    class: ['font-bold', 'm-0', 'py-3 px-5', 'text-surface-800 dark:text-white/80', 'cursor-auto']
  },
  filtercontainer: {
    class: 'relative'
  },
  filterinput: {
    class: [
      'leading-none',
      'w-full h-[60px] !px-[50px] my-[1rem]',
      'text-[#4b4b4b] text-[18px]',
      'bg-[#050505]',
      'rounded-lg',
      'transition',
      'duration-200',
      ' ring-2 ring-black focus:ring-primary-50',
      'outline-none',
      'placeholder:text-[#B3B3B3]'
    ]
  },
  filtericon: {
    class: ['absolute', 'top-1/2 left-[1.5rem]', '-mt-2', 'text-[#B3B3B3]']
  },
  emptymessage: {
    class: ['leading-none', 'py-3 px-5', 'text-surface-800 dark:text-white/80', 'bg-transparent']
  }
}
