export const IS_INPUT_SUPPORTED = (function () {
  try {
    // `InputEvent`
    const event = new InputEvent('input', {
      data: 'xyz',
      inputType: 'deleteContentForward'
    })
    let support = false

    const el = document.createElement('input')
    el.addEventListener('input', function (e) {
      if (e.inputType === 'deleteContentForward') {
        support = true
      }
    })

    el.dispatchEvent(event)
    return support
  } catch (error) {
    return false
  }
})()
/**
 * A normalized event from InputEvent and KeyboardEvent that works from ie11
 * over modern browsers to android browsers (because that beast is worse than ie6)
 */
export interface CompatibleInputEvent {
  data?: string
  inputType?: string
  navigationType?: string
  originalEvent: KeyboardEvent | InputEvent
}
interface Tags {
  value: string[]
  canDeleteChip: number
  recentDelete: boolean
}
export const normalizeInputEvent = function (
  event: KeyboardEvent | InputEvent
): CompatibleInputEvent {
  const e: CompatibleInputEvent = {
    originalEvent: event
  }

  if (event instanceof KeyboardEvent) {
    if (event.key === 'Backspace') {
      e.inputType = 'deleteContentBackward'
      e.navigationType = 'cursorLeft'
    } else if (event.key === 'Delete') {
      e.inputType = 'deleteContentForward'
    } else if (event.key.startsWith('Arrow')) {
      e.navigationType = event.key.replace('Arrow', 'cursor')
    } else {
      e.data = event.key
      e.inputType = 'insertText'
    }
  } else {
    const { inputType } = event
    e.inputType = inputType
    e.data = event.data

    if (inputType === 'insertText') {
      e.navigationType = 'cursorRight'
    }
  }

  return e
}

export const handleEvent = (element: HTMLInputElement, e: CompatibleInputEvent, tags: Tags) => {
  element.value = element.value.trim()

  if (e.inputType !== 'deleteContentBackward') {
    tags.canDeleteChip = 0
    tags.recentDelete = false
  } else if (element.value === '' && !tags.recentDelete) {
    tags.canDeleteChip++
  }

  if (e.data === ' ' || e.data === 'Enter') {
    if (tags.value.includes(element.value)) {
      return
    }
    tags.value = [...tags.value, element.value.trim()]
    element.value = ''
    tags.canDeleteChip = 1
    tags.recentDelete = false
  } else if (
    e.inputType === 'deleteContentBackward' &&
    element.value === '' &&
    tags.canDeleteChip === 2 &&
    tags.value.length > 0
  ) {
    tags.value = tags.value.slice(0, -1)
    element.value = ''
    tags.recentDelete = true
  }
}
