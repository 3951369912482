import * as K from '~/helpers/events-support'
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('chips-events', {
    mounted(el, binding) {
      el.addEventListener(
        'keydown',
        (event) => {
          const e = K.normalizeInputEvent(event)

          if (!K.IS_INPUT_SUPPORTED || event.key.length > 1) {
            K.handleEvent(event.target, e, binding.value)
          }
        },
        false
      )

      el.addEventListener(
        'input',
        (event) => {
          if (K.IS_INPUT_SUPPORTED) {
            K.handleEvent(event.target, K.normalizeInputEvent(event), binding.value)
          }
        },
        false
      )
    }
  })
})
