export default {
  root: ({ props }) => ({
    class: [
      // Flexbox
      'flex flex-col',

      // Position
      'relative',
      {
        '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
          props.position == 'full'
      },

      // Size
      {
        'h-full w-full max-w-[28rem]': props.position == 'left' || props.position == 'right',
        'h-auto w-full': props.position == 'top' || props.position == 'bottom'
      },

      // Shape
      'shadow-xl',

      // Colors
      'bg-dark',
      'text-surface-700',

      // Transitions
      'transition-transform',
      'duration-300',

      // Misc
      'pointer-events-auto'
    ]
  }),
  header: {
    class: [
      // Flexbox and Alignment
      'flex items-center justify-end',
      'shrink-0',

      // Spacing
      'px-6 pt-6',

      // Colors
      'bg-dark',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  title: {
    class: ['font-semibold leading-6 text-base m-0']
  },
  icons: {
    class: ['flex items-center']
  },
  closeButton: {
    class: [
      'relative',

      // Flexbox and Alignment
      'flex items-center justify-center',

      // Size and Spacing
      'mr-2',
      'last:mr-0',
      'w-6 h-6',

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'text-primary-text',
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // Misc
      'overflow-hidden'
    ]
  },
  closeicon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-4',
      'h-4'
    ]
  },
  content: {
    class: [
      // Spacing and Size
      'px-6 pb-6',
      'mt-6',
      'h-full',
      'w-full',

      // Growth and Overflow
      'grow',
      'overflow-y-auto'
    ]
  },
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition',
      'duration-300',
      '!z-[5000]',
      { 'p-5': !props.position == 'full' },

      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent dark:has-[.mask-active]:bg-transparent bg-dark/80 dark:bg-dark/80':
          props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal
      }
    ]
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
          enterFromClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
          leaveToClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active'
        }
      : props.position === 'bottom'
        ? {
            enterFromClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
            leaveToClass: 'translate-x-0 translate-y-full translate-z-0 mask-active'
          }
        : props.position === 'left'
          ? {
              enterFromClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
              leaveToClass: '-translate-x-full translate-y-0 translate-z-0 mask-active'
            }
          : props.position === 'right'
            ? {
                enterFromClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
                leaveToClass: 'translate-x-full translate-y-0 translate-z-0 mask-active'
              }
            : {
                enterFromClass: 'opacity-0 mask-active',
                enterActiveClass: 'transition-opacity duration-400 ease-in',
                leaveActiveClass: 'transition-opacity duration-400 ease-in',
                leaveToClass: 'opacity-0 mask-active'
              }
  }
}
