import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import titles_m9SjywKgWm from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import siteConfig_XJTWbJLGEl from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_jg3TSEXw60 from "/app/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/app/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_sentry_client_NS66C2vq64 from "/app/src/plugins/01.sentry.client.ts";
import chips_events_KBlNcEW5OY from "/app/src/plugins/chips-events.ts";
import dayjs_NBCB9zH19f from "/app/src/plugins/dayjs.ts";
import dotlottie_client_2ZSKML7tRO from "/app/src/plugins/dotlottie.client.ts";
import polyfills_client_QKLHjv6yM3 from "/app/src/plugins/polyfills.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  titles_m9SjywKgWm,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  plugin_t2GMTTFnMT,
  plugin_AOuQ1DYzjk,
  plugin_jg3TSEXw60,
  plugin_tbFNToZNim,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  _01_sentry_client_NS66C2vq64,
  chips_events_KBlNcEW5OY,
  dayjs_NBCB9zH19f,
  dotlottie_client_2ZSKML7tRO,
  polyfills_client_QKLHjv6yM3
]