import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import fr from 'dayjs/locale/fr'

export default defineNuxtPlugin(() => {
  dayjs.extend(localizedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.locale('fr', fr, true)
  dayjs.extend(updateLocale)

  for (const locale of ['en', 'fr']) {
    dayjs.updateLocale(locale, {
      weekStart: 5
    })
  }
})
