
// @ts-nocheck
import locale__app_src_lang_en_json from "../src/lang/en.json";
import locale__app_src_lang_fr_json from "../src/lang/fr.json";


export const localeCodes =  [
  "en",
  "fr"
]

export const localeMessages = {
  "en": [{ key: "../src/lang/en.json", load: () => Promise.resolve(locale__app_src_lang_en_json), cache: true }],
  "fr": [{ key: "../src/lang/fr.json", load: () => Promise.resolve(locale__app_src_lang_fr_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        "lang/en.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "lang/fr.json"
      ]
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "brands/index": {
      "en": "/brands",
      "fr": "/nos-marques"
    },
    "articles/index": {
      "en": "/articles",
      "fr": "/les-nouvelles"
    },
    "articles/[slug]": {
      "en": "/articles/[slug]",
      "fr": "/nouvelles/[slug]"
    },
    "movies/[...slug]": {
      "en": "/movies/[...slug]",
      "fr": "/films/[...slug]"
    },
    "movies/index": {
      "en": "/movies",
      "fr": "/films"
    },
    "about-us/index": {
      "en": "/about-us",
      "fr": "/mediafilm-c-est-quoi"
    },
    "timeline/index": {
      "en": "/timeline",
      "fr": "/notre-histoire"
    },
    "lists/index": {
      "en": "/lists",
      "fr": "/listes"
    },
    "lists/[id]": {
      "en": "/lists/[id]",
      "fr": "/listes/[id]"
    },
    "search": {
      "en": "/search",
      "fr": "/recherche"
    },
    "privacy/index": {
      "en": "/privacy",
      "fr": "/politique-de-confidentialite"
    },
    "cookies/index": {
      "en": "/cookies",
      "fr": "/politique-de-cookies"
    },
    "authors/[slug]": {
      "en": "/authors/[slug]",
      "fr": "/auteurs/[slug]"
    },
    "artists/[slug]": {
      "en": "/artists/[slug]",
      "fr": "/artistes/[slug]"
    },
    "tv-schedule": {
      "en": "/tv-schedule",
      "fr": "/dans-ma-tele"
    },
    "movies/weekly-releases": {
      "en": "/movies/weekly-releases",
      "fr": "/films/sorties-de-la-semaine"
    },
    "movies/upcoming-releases": {
      "en": "/movies/upcoming-releases",
      "fr": "/films/sorties-a-venir"
    },
    "movies/featured": {
      "en": "/movies/featured",
      "fr": "/films/a-l-affiche"
    },
    "not-logged": {
      "en": "/not-logged",
      "fr": "/pas-connecte"
    },
    "advanced-search": {
      "en": "/advanced-search",
      "fr": "/recherche-avancee"
    },
    "index": {
      "en": "/",
      "fr": "/"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "iso": "en-US",
      "name": "English",
      "files": [
        {
          "path": "lang/en.json"
        }
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        {
          "path": "lang/fr.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

