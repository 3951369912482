import { default as _91_46_46_46slug_93XOh4cis8EcMeta } from "/app/src/pages/[...slug].vue?macro=true";
import { default as _404GzuqBiFJfuMeta } from "/app/src/pages/404.vue?macro=true";
import { default as index6dle6k33gZMeta } from "/app/src/pages/about-us/index.vue?macro=true";
import { default as advanced_45searche2fTX3fgEkMeta } from "/app/src/pages/advanced-search.vue?macro=true";
import { default as _91slug_93KfS7allcdsMeta } from "/app/src/pages/articles/[slug].vue?macro=true";
import { default as index22HbZ9SXNhMeta } from "/app/src/pages/articles/index.vue?macro=true";
import { default as _91slug_93bwPM9Dvw05Meta } from "/app/src/pages/artists/[slug].vue?macro=true";
import { default as _91slug_93GZ0Rv50RI6Meta } from "/app/src/pages/authors/[slug].vue?macro=true";
import { default as index5Lmo6eJlEhMeta } from "/app/src/pages/brands/index.vue?macro=true";
import { default as indexauzhmXaTPIMeta } from "/app/src/pages/cookies/index.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91id_93iMgefeR7ZeMeta } from "/app/src/pages/lists/[id].vue?macro=true";
import { default as _91_46_46_46slug_93Tvz2RbQIscMeta } from "/app/src/pages/movies/[...slug].vue?macro=true";
import { default as featured8KJRmY7dLKMeta } from "/app/src/pages/movies/featured.vue?macro=true";
import { default as upcoming_45releasesV95LjMt7B9Meta } from "/app/src/pages/movies/upcoming-releases.vue?macro=true";
import { default as weekly_45releaseskFXjFHhZW4Meta } from "/app/src/pages/movies/weekly-releases.vue?macro=true";
import { default as not_45logged6OKfFyJkYuMeta } from "/app/src/pages/not-logged.vue?macro=true";
import { default as index5UaCbwHmc5Meta } from "/app/src/pages/privacy/index.vue?macro=true";
import { default as search5UN5dWklNeMeta } from "/app/src/pages/search.vue?macro=true";
import { default as indexvZYduO3BGzMeta } from "/app/src/pages/timeline/index.vue?macro=true";
import { default as tv_45scheduleDV4C10YVIjMeta } from "/app/src/pages/tv-schedule.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr",
    path: "/:slug(.*)*",
    component: () => import("/app/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "404___en",
    path: "/en/404",
    component: () => import("/app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "404___fr",
    path: "/404",
    component: () => import("/app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us___fr",
    path: "/mediafilm-c-est-quoi",
    component: () => import("/app/src/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "advanced-search___en",
    path: "/en/advanced-search",
    component: () => import("/app/src/pages/advanced-search.vue").then(m => m.default || m)
  },
  {
    name: "advanced-search___fr",
    path: "/recherche-avancee",
    component: () => import("/app/src/pages/advanced-search.vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___en",
    path: "/en/articles/:slug",
    component: () => import("/app/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___fr",
    path: "/nouvelles/:slug",
    component: () => import("/app/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "articles___en",
    path: "/en/articles",
    component: () => import("/app/src/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___fr",
    path: "/les-nouvelles",
    component: () => import("/app/src/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "artists-slug___en",
    path: "/en/artists/:slug",
    component: () => import("/app/src/pages/artists/[slug].vue").then(m => m.default || m)
  },
  {
    name: "artists-slug___fr",
    path: "/artistes/:slug",
    component: () => import("/app/src/pages/artists/[slug].vue").then(m => m.default || m)
  },
  {
    name: "authors-slug___en",
    path: "/en/authors/:slug",
    component: () => import("/app/src/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: "authors-slug___fr",
    path: "/auteurs/:slug",
    component: () => import("/app/src/pages/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: "brands___en",
    path: "/en/brands",
    component: () => import("/app/src/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "brands___fr",
    path: "/nos-marques",
    component: () => import("/app/src/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___en",
    path: "/en/cookies",
    component: () => import("/app/src/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___fr",
    path: "/politique-de-cookies",
    component: () => import("/app/src/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lists-id___en",
    path: "/en/lists/:id",
    component: () => import("/app/src/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: "lists-id___fr",
    path: "/listes/:id",
    component: () => import("/app/src/pages/lists/[id].vue").then(m => m.default || m)
  },
  {
    name: "movies-slug___en",
    path: "/en/movies/:slug(.*)*",
    component: () => import("/app/src/pages/movies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "movies-slug___fr",
    path: "/films/:slug(.*)*",
    component: () => import("/app/src/pages/movies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "movies-featured___en",
    path: "/en/movies/featured",
    component: () => import("/app/src/pages/movies/featured.vue").then(m => m.default || m)
  },
  {
    name: "movies-featured___fr",
    path: "/films/a-l-affiche",
    component: () => import("/app/src/pages/movies/featured.vue").then(m => m.default || m)
  },
  {
    name: "movies-upcoming-releases___en",
    path: "/en/movies/upcoming-releases",
    component: () => import("/app/src/pages/movies/upcoming-releases.vue").then(m => m.default || m)
  },
  {
    name: "movies-upcoming-releases___fr",
    path: "/films/sorties-a-venir",
    component: () => import("/app/src/pages/movies/upcoming-releases.vue").then(m => m.default || m)
  },
  {
    name: "movies-weekly-releases___en",
    path: "/en/movies/weekly-releases",
    component: () => import("/app/src/pages/movies/weekly-releases.vue").then(m => m.default || m)
  },
  {
    name: "movies-weekly-releases___fr",
    path: "/films/sorties-de-la-semaine",
    component: () => import("/app/src/pages/movies/weekly-releases.vue").then(m => m.default || m)
  },
  {
    name: "not-logged___en",
    path: "/en/not-logged",
    meta: not_45logged6OKfFyJkYuMeta || {},
    component: () => import("/app/src/pages/not-logged.vue").then(m => m.default || m)
  },
  {
    name: "not-logged___fr",
    path: "/pas-connecte",
    meta: not_45logged6OKfFyJkYuMeta || {},
    component: () => import("/app/src/pages/not-logged.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/app/src/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy___fr",
    path: "/politique-de-confidentialite",
    component: () => import("/app/src/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/recherche",
    component: () => import("/app/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "timeline___en",
    path: "/en/timeline",
    component: () => import("/app/src/pages/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "timeline___fr",
    path: "/notre-histoire",
    component: () => import("/app/src/pages/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "tv-schedule___en",
    path: "/en/tv-schedule",
    component: () => import("/app/src/pages/tv-schedule.vue").then(m => m.default || m)
  },
  {
    name: "tv-schedule___fr",
    path: "/dans-ma-tele",
    component: () => import("/app/src/pages/tv-schedule.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]