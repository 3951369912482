export default {
  root: {
    class: [
      'rounded-xl shadow-lg w-[90%] sm:!w-[342px] md:!w-[480px] ',
      'relative left-0  right-0 md:right-[100%]  !mx-auto ',
      'z-40 transform origin-center',
      'bg-[#1B1B1D] dark:bg-[#1B1B1D]'
    ]
  },
  content: {
    class: 'items-center flex flex-col'
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
