export default {
  root: ({ props }) => ({
    class: [
      'flex',
      {
        'opacity-60 select-none pointer-events-none cursor-default': props.disabled
      }
    ]
  }),
  container: ({ state, props, parent }) => {
    var _a, _b
    return {
      class: [
        //flex
        'flex items-center flex-wrap gap-1 rounded-lg text-lg ml-0 mb-0',
        'bg-[#050505] !ring-[#050505] !placeholder-[#4B4B4B] text-white/60 py-3.5 px-6 w-[17.5rem]',
        // Color
        { 'border-surface-300 dark:border-surface-600': !props.invalid },
        // Invalid State
        { 'border-red-500 dark:border-red-400': props.invalid },
        // States
        { 'ring !ring-primary-50 dark:!ring-primary-50': state.focused },

        { 'hover:border-primary': !props.invalid },
        // Filled State *for FloatLabel
        {
          filled:
            ((_a = parent.instance) == null ? void 0 : _a.$name) == 'FloatLabel' &&
            props.modelValue !== null &&
            ((_b = props.modelValue) == null ? void 0 : _b.length) !== 0
        },
        // Transition
        'transition-colors duration-200',
        // Misc
        'cursor-text overflow-hidden',
        'appearance-none'
      ]
    }
  },
  inputtoken: {
    class: ['py-1.5 px-0', 'w-[50px] flex flex-auto']
  },
  input: {
    class: [
      'text-lg leading-[normal]',
      'w-full',
      'p-0 m-0',
      'appearance-none rounded-none',
      'border-0 outline-none',
      'shadow-none',
      'text-[#4B4B4B]',
      'bg-transparent'
    ]
  },
  token: {
    class: [
      'inline-flex items-center',
      'py-1.5 px-3',
      'rounded-[1.14rem]',
      'text-white font-medium dark:text-white',
      'bg-[#363636] dark:bg-[#363636]'
    ]
  },
  label: {
    class: 'leading-[normal]'
  },
  removeTokenIcon: {
    class: [
      'rounded-md leading-6',
      'ml-2',
      'w-4 h-4',
      'transition duration-200 ease-in-out text-[#757575]',
      'cursor-pointer'
    ]
  }
}
