export default {
  root: ({ props, state }) => ({
    class: [
      // Display and Position
      'inline-flex',
      'relative',

      // Shape
      'rounded-xl',
      'shadow-sm',

      // Color and Background
      'bg-dark',
      'py-4 px-4 pl-7',

      // States
      {
        'ring-2 ring-inset ring-primary-500': state.focused
      },

      // Invalid State
      { 'ring-red-500': props.invalid && !state.focused },

      // Misc
      'cursor-default',
      'select-none',
      { 'opacity-60': props.disabled, 'pointer-events-none': props.disabled }
    ]
  }),
  labelContainer: {
    class: 'overflow-hidden flex flex-auto cursor-pointer'
  },
  label: ({ props }) => ({
    class: [
      'block leading-5',

      props.display === 'chip' && props?.modelValue?.length > 0 ? 'py-1' : 'py-1.5',

      // Color
      'text-secondary-text',
      'placeholder:text-secondary-text',

      // Transitions
      'transition duration-200',

      // Misc
      'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis'
    ]
  }),
  token: {
    class: [
      // Flexbox
      'inline-flex items-center',

      // Spacing
      'py-0.5 px-3 mr-2',

      // Shape
      'rounded-[1.14rem]',

      // Colors
      'text-surface-700 dark:text-white/70',
      'bg-surface-200 dark:bg-surface-700'
    ]
  },
  removeTokenIcon: {
    class: [
      // Shape
      'rounded-md leading-6',

      // Spacing
      'ml-2',

      // Size
      'w-4 h-4',

      // Transition
      'transition duration-200 ease-in-out',

      // Misc
      'cursor-pointer'
    ]
  },
  trigger: {
    class: [
      // Font
      '!text-base',

      // Flexbox
      'flex items-center justify-center',
      'shrink-0',

      // Color and Background
      'bg-transparent',
      'text-surface-500',

      // Size
      'w-12',

      // Shape
      'rounded-tr-md',
      'rounded-br-md'
    ]
  },
  panel: {
    class: [
      // Position
      'absolute top-0 left-0',
      '!mt-[0.5rem]',
      '!z-[6000]',
      // Shape
      'border-0',
      'rounded-xl',
      'shadow-md',

      // Color
      'bg-dark',
      'text-surface-800 dark:text-white/80'
    ]
  },
  header: {
    class: [
      'flex items-center justify-between',
      // Spacing
      'py-2 px-4 !pl-8',
      'm-0',

      // Shape
      'border-b',
      'rounded-tl-md',
      'rounded-tr-md',

      // Color
      'text-surface-700 dark:text-white/80',
      'bg-dark ',
      'border-[#363636]'
    ]
  },
  headerCheckboxContainer: {
    class: [
      'relative',

      // Alignment
      'inline-flex',
      'align-bottom',

      // Size
      'w-4',
      'h-4',

      // Spacing
      'mr-2',

      // Misc
      'cursor-default',
      'select-none'
    ]
  },
  headerCheckbox: {
    root: {
      class: [
        'relative',

        // Alignment
        'inline-flex',
        'align-bottom',

        // Size
        'w-4',
        'h-4',

        // Spacing
        'mr-2',

        // Misc
        'cursor-default',
        'select-none'
      ]
    },
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',

        // Size
        'w-4',
        'h-4',

        // Shape
        'rounded',
        'border-2',

        // Colors
        'text-secondary-text',
        {
          'border-[#4B4B4B] bg-[#0F0F10]': !context.checked,
          'border-primary-50 bg-primary-50 ': context.checked
        },

        {
          'ring-2 ring-primary-500 dark:ring-primary-400': !props.disabled && context.focused,
          'cursor-default opacity-60': props.disabled
        },

        // States
        {
          'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400':
            !props.disabled,
          'cursor-default opacity-60': props.disabled
        },

        // Transitions
        'transition-colors',
        'duration-200'
      ]
    }),
    input: {
      class: [
        'peer',

        // Size
        'w-full ',
        'h-full',

        // Position
        'absolute',
        'top-0 left-0',
        'z-10',

        // Spacing
        'p-0',
        'm-0',

        // Shape
        'rounded',
        'border',

        // Shape
        'opacity-0',
        'rounded-md',
        'outline-none',

        // Misc
        'appearance-none'
      ]
    },
    icon: {
      class: [
        // Font
        'text-normal',

        // Size
        'w-3',
        'h-3',

        // Colors
        'text-white dark:text-surface-900',

        // Transitions
        'transition-all',
        'duration-200'
      ]
    }
  },
  itemCheckbox: {
    root: {
      class: [
        'relative',

        // Alignment
        'inline-flex',
        'align-bottom',

        // Size
        'w-4',
        'h-4',

        // Spacing
        'mr-2',

        // Misc
        'cursor-default',
        'select-none'
      ]
    },
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex',
        'items-center',
        'justify-center',

        // Size
        'w-4',
        'h-4',

        // Shape
        'rounded',
        'border-2',

        // Colors
        'text-[#4B4B4B]',
        {
          'border-[#4B4B4B] bg-[#0F0F10]': !context.checked,
          'border-primary-50 bg-primary-50': context.checked
        },

        {
          'ring-2 ring-primary-500 dark:ring-primary-400': !props.disabled && context.focused,
          'cursor-default opacity-60': props.disabled
        },

        // States
        {
          'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400':
            !props.disabled,
          'cursor-default opacity-60': props.disabled
        },

        // Transitions
        'transition-colors',
        'duration-200'
      ]
    }),
    input: {
      class: [
        'peer',

        // Size
        'w-full ',
        'h-full',

        // Position
        'absolute',
        'top-0 left-0',
        'z-10',

        // Spacing
        'p-0',
        'm-0',

        // Shape
        'rounded',
        'border',

        // Shape
        'opacity-0',
        'rounded-md',
        'outline-none',
        // Misc
        'appearance-none'
      ]
    },
    icon: {
      class: [
        // Font
        'text-normal',

        // Size
        'w-3',
        'h-3',

        // Colors
        'text-white dark:text-surface-900',

        // Transitions
        'transition-all',
        'duration-200'
      ]
    }
  },
  closeButton: {
    class: [
      'relative',

      // Flexbox and Alignment
      'flex items-center justify-center',

      // Size and Spacing
      'mr-2',
      'last:mr-0',
      'w-6 h-6',

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'text-white',
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:text-surface-700 dark:hover:text-white/80',
      'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-inset',
      'focus:ring-primary-500 dark:focus:ring-primary-400',

      // Misc
      'overflow-hidden'
    ]
  },
  closeButtonIcon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-3',
      'h-3'
    ]
  },
  wrapper: {
    class: [
      // Sizing
      'max-h-[15rem]',

      // Misc
      'overflow-auto'
    ]
  },
  list: {
    class: 'py-1 list-none m-0 bg-dark'
  },
  item: ({ context }) => ({
    class: [
      // Font
      '@sm:text-base',

      '!leading-[1rem]',
      { 'font-normal': !context.selected, 'font-bold': context.selected },

      // Flexbox
      'flex items-center',

      // Position
      'relative',

      // Shape
      'border-0',
      'rounded-none',

      // Spacing
      'm-0',
      'py-2 px-4 !pl-8',

      // Color
      { 'text-secondary-text ': !context.focused && !context.selected },
      {
        'text-white': context.focused && !context.selected
      },

      // States
      'hover:text-white ',

      // Misc
      'cursor-pointer',
      'overflow-hidden',
      'whitespace-nowrap'
    ]
  }),
  itemgroup: {
    class: [
      // Font
      'font-bold',
      '@sm:text-base',

      // Spacing
      'm-0',
      'py-2 px-4',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-surface-0 dark:bg-surface-600/80',

      // Misc
      'cursor-auto'
    ]
  },
  filtercontainer: {
    class: 'relative w-full mr-2'
  },
  filterinput: {
    class: [
      // Font
      'font-sans',
      'leading-none',
      '@sm:text-sm',

      // Sizing
      'py-1.5 px-3',
      'pr-7',
      '-mr-7',
      'w-full',

      // Color
      'text-surface-700 dark:text-white/80',
      'bg-surface-0 dark:bg-surface-900',
      'placeholder:text-secondary-text',
      'ring-1 ring-inset ring-surface-300 dark:ring-surface-700',

      // Shape
      'border-0',
      'rounded-md',
      'appearance-none',

      // States
      'focus:ring-2 focus:ring-inset focus:outline-none focus:outline-offset-0',
      'focus:ring-primary-600 dark:focus:ring-primary-500',

      // Misc
      'appearance-none'
    ]
  },
  filtericon: {
    class: ['absolute', 'top-1/2 right-3', '-mt-2']
  },
  clearicon: {
    class: [
      // Color
      'text-surface-500',

      // Position
      'absolute',
      'top-1/2',
      'right-12',

      // Spacing
      '-mt-2'
    ]
  },
  emptymessage: {
    class: [
      // Font
      'leading-none',
      '@sm:text-base',

      // Spacing
      'py-2 px-4',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-transparent'
    ]
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
