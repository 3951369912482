import { defineNuxtPlugin } from '#app/nuxt'
import { LazyChips, LazyDropdown, LazyInputText, LazyListbox, LazyMultiSelect, LazyButton, LazyConfirmDialog, LazyDialog, LazyDynamicDialog, LazyOverlayPanel, LazySidebar, LazyMenu, LazyGalleria, LazyImage, LazySkeleton } from '#components'
const lazyGlobalComponents = [
  ["Chips", LazyChips],
["Dropdown", LazyDropdown],
["InputText", LazyInputText],
["Listbox", LazyListbox],
["MultiSelect", LazyMultiSelect],
["Button", LazyButton],
["ConfirmDialog", LazyConfirmDialog],
["Dialog", LazyDialog],
["DynamicDialog", LazyDynamicDialog],
["OverlayPanel", LazyOverlayPanel],
["Sidebar", LazySidebar],
["Menu", LazyMenu],
["Galleria", LazyGalleria],
["Image", LazyImage],
["Skeleton", LazySkeleton],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
