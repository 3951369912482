import global from './global.js'
import button from './button'
import dropdown from './dropdown'
import inputtext from './inputtext'
import toast from './toast'
import dialog from './dialog'
import skeleton from './skeleton'
import menu from './menu'
import sidebar from './sidebar'
import multiselect from './multiselect'
import listbox from './listbox/index.js'
import image from './image/index.js'
import galleria from './galleria/index.js'
import overlaypanel from './overlaypanel/index.js'
import chips from './chips/index.js'

export default {
  global,
  button,
  dropdown,
  inputtext,
  toast,
  listbox,
  dialog,
  skeleton,
  menu,
  sidebar,
  multiselect,
  image,
  galleria,
  overlaypanel,
  chips
}
